import React, { useState } from "react";
import "./styles.css";
import axios from "axios";
import { Button, Form, Input, Divider } from "antd";

const api = "https://shidian.baike.com/doc_duplicate_check/";
// const api = "http://localhost:3000/";

const localCode = localStorage.getItem("__local_code");

interface RespData {
  title: string;
  msg: string;
  error_code: number;
}

interface ReqData {
  code: string;
  larkDocUrl: string;
}

const CForm: React.FC = () => {
  const [data, setData] = useState<RespData | null>(null);
  const [loading, setLoading] = useState(false);
  const onFinish = (values: ReqData) => {
    localStorage.setItem("__local_code", values.code);
    setLoading(true);
    setData(null);
    axios
      .get(api, {
        params: values
      })
      .then((resp) => {
        setLoading(false);
        setData(resp.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="验证码"
          name="code"
          initialValue={localCode}
          rules={[{ required: true, message: "请输入验证码" }]}
        >
          <Input placeholder="请输入验证码" />
        </Form.Item>

        <Form.Item
          label="文档地址"
          name="larkDocUrl"
          rules={[{ required: true, message: "请输入文档地址!" }]}
        >
          <Input placeholder="请输入文档地址" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
          <Button type="primary" htmlType="submit">
            {loading ? "数据加载中..." : "提交"}
          </Button>
        </Form.Item>
      </Form>

      <Divider />
      <div className="result">
        {data ? (
          <>
            <p className="title">标题： {data.title || "未知"}</p>
            <p
              style={{
                color: data.error_code === 0 ? "green" : "red"
              }}
            >
              {data.msg}
            </p>
          </>
        ) : loading && (
          "..."
        )}
      </div>
    </>
  );
};

export default CForm;
