import React from "react";
import "./styles.css";
import { Layout } from "antd";
import CForm from "./CForm";

const { Header, Content } = Layout;

const App: React.FC = () => (
  <>
    <Layout>
      <Header className="header">查重小工具</Header>
      <Content>
        <CForm />
      </Content>
    </Layout>
  </>
);

export default App;
